// @ts-ignore
import * as Sentry from '@sentry/nuxt'

Sentry.init({
  dsn: 'https://a497cf76b328b7f9bd89083ed906be6c@o4507888360226816.ingest.us.sentry.io/4507888589930496',
  integrations: [Sentry.browserTracingIntegration()],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0
})
